.site-header-container {
  position: relative;
  z-index: 12;
}

.site-header {
  position: fixed;
  width: 100%;
  left: 0;
  transition: .3s;

  > .inner {
    @extend %constrain;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: em(24);
    padding-bottom: em(24);
    transition: .3s;
    position: relative;
    z-index: 20;
  }

  a {
    display: block;
    transition: .3s;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
    }
  }

  @media (min-width: 992px) {
    padding: 0;
  }

  &__logo {
    img.scroll {
      display: none;
      visibility: hidden;
    }

    @media (max-width: 991px) {
      flex-grow: 1;
      text-align: left;
    }

    @media (max-width: 767px) {
      flex-grow: 1;
      text-align: left;

      body:not(.lp) & {
        img.large {
          display: none;
          visibility: hidden;
        }
      }
    }

    @media (min-width: 768px) {
      body:not(.lp) & {
        img.small {
          display: none;
          visibility: hidden;
        }
      }
    }

    body.lp & {
      @media (max-width: 767px) {
        justify-content: center;
        text-align: center;
      }

      img.small {
        display: none !important;
        visibility: hidden !important;
      }
    }
  }

  &__main-menu {
    .hs-menu-depth-1 {
      position: relative;
      margin: 0 em(12);
      transition: .3s;

      > a {
        color: #fff;
        padding: em(12) 0;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: em(14);
        position: relative;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border-top: 2px solid transparent;
          transition: .3s;
        }
      }

      &.hs-item-has-children:not(:last-child) {
        position: relative;

        > a {
          padding-right: em(20);

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid #fff;
            display: inline-block;
            margin-left: 5px;
            vertical-align: middle;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &:hover {
          > a:before {
            border-color: #fff;
          }
        }
      }

      &:not(:last-child) {
        &.active {
          > a {
            &:before {
              border-color: $blue;
            }
          }
        }

        &:hover {
          > a {
            &:before {
              border-color: #fff;
            }
          }

          .hs-menu-children-wrapper {
            display: block;
          }
        }
      }

      &:last-child {
        margin-left: em(10);

        > a {
          @extend %cta-btn;

          @extend %cta-btn--arrow;

          font-size: 13px;
          padding: em(12) em(25);
        }

        .hs-menu-children-wrapper {
          border-radius: 0 0 2px 2px;
        }

        &.active {
          > a {
            border-radius: 2px 2px 0 0;
          }

          .hs-menu-children-wrapper {
            background-color: $light-orange;
            display: block;
            width: 100%;
            text-align: center;

            li {
              padding: 0;

              &:last-child {
                border-radius: 0 0 2px 2px;
                overflow: hidden;
              }
            }

            a {
              &:hover {
                background-color: #fff;
                color: $orange;
              }
            }
          }
        }
      }
    }

    .hs-menu-children-wrapper {
      display: none;
      position: absolute;
      background-color: $gray-3;
      text-align: left;
      min-width: em(150);
      right: 0;

      .hs-menu-depth-2 {
        display: block;

        &:first-child {
          padding-top: em(10);
        }

        &:last-child {
          padding-bottom: em(10);
        }

        > a {
          color: #fff;
          display: block;
          padding: em(12) em(17);
          font-size: em(14);
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 1px;
          width: 100%;

          &:hover {
            background-color: $gray-6;
          }
        }

        &.active {
          > a {
            color: $blue;
          }

          &:hover {
            > a {
              color: #fff;
            }
          }
        }
      }
    }

    @media (max-width: 991px) {
      .hs-menu-depth-1 {
        &:not(:last-child) {
          display: none;
          visibility: hidden;
        }
      }
    }
  }

  &__secondary-menu {
    .hs-menu-depth-1 {
      > a {
        padding: .5em;
      }
    }

    @media (max-width: 479px) {
      display: none;
    }
  }

  &--scroll {
    &:not(.site-header--light) {
      background-color: rgba(#000, 0.8);
    }

    > .inner {
      padding-top: em(14);
      padding-bottom: em(14);
    }
  }

  &--light {
    background-color: #fff;

    .site-header__main-menu {
      .hs-menu-depth-1.hs-item-has-children:not(:last-child) > a:after {
        border-top: 5px solid $gray-dark !important;
      }

      .hs-menu-depth-1:not(:last-child) {
        > a {
          color: $gray-dark;
        }

        &:hover {
          > a {
            &:before {
              border-color: $gray-dark;
            }
          }
        }
      }
    }

    &.site-header--scroll {
      .hs-menu-depth-1.hs-item-has-children:not(:last-child) > a:after {
        border-top: 5px solid #fff !important;
      }

      .site-header__main-menu {
        .hs-menu-depth-1:not(:last-child) {
          > a {
            color: #fff;
          }

          &:hover {
            > a {
              &:before {
                border-color: #fff;
              }
            }
          }
        }
      }

      @media (min-width: 768px) {
        img.scroll {
          display: block;
          visibility: visible;
        }

        img:not(.scroll) {
          display: none;
          visibility: hidden;
        }
      }
    }
  }

  &--scroll {
    background-color: rgba(#000, 0.8);
  }
}
