%dynamic-menu {
  .hs-menu-depth-1:not(.active-branch) {
    display: none;
    visibility: hidden;
  }
}

.submenu {
  position: relative;
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: top;
    }

    &.hs-menu-children-wrapper {
      display: none;
    }
  }

  a {
    display: block;
    padding: 1em;
  }

  &--static {}

  &--dynamic {
    @extend %dynamic-menu;
  }
}
