.accordion {}

.panel {
  border-bottom: 2px solid $gray-light;
  text-align: left;
  padding: em(15) em(15) 0 em(15);

  &__title {
    cursor: pointer;
    text-transform: none;
    padding: em(15) em(45) em(15) 0;
    margin-bottom: 0;
    position: relative;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: em(5) solid transparent;
      border-right: em(5) solid transparent;
      border-top: em(7) solid $blue;
      position: absolute;
      top: 50%;
      right: em(15);
      transform: translateY(-50%);

      .panel.active & {
        border-top: 0;
        border-bottom: em(7) solid $blue;
      }
    }
  }

  &__content {
    padding-bottom: em(50);
  }

  &:first-child {
    border-top: 2px solid $gray-light;
  }

  &:not(.active) {
    .panel__content {
      display: none;
      visibility: hidden;
    }
  }
}
