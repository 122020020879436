body.lp {
  .site-header-container {
    &.lp-disabled-header {
      display: none;
      visibility: hidden;
    }
  }

  .site-header {
    position: absolute !important;

    > .inner {
      justify-content: center;
    }

    &__main-menu,
    &__secondary-menu,
    .menu-trigger__container,
    .mobile-menu {
      display: none;
    }
  }

  .site-footer {
    &__main-menu {
      //display: none;
   
    }
  }
}
