.our-community {
  h5 {
    font-size: em(30);
    letter-spacing: 2px;
  }

  h3 {
    font-size: em(55);
    margin-bottom: 0;
  }

  p {
    font-size: em(18);
  }

  .nested-layout__grid {
    .nested-layout__column {
      position: relative;
      padding-top: em(10);
      padding-bottom: em(10);

      &:not(:last-child) {
        @media (min-width: 768px) {
          &:after {
            content: '';
            border-right: 2px solid $gray;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
          }
        }
      }

      @media (min-width: 768px) {
        padding-top: em(30);
        padding-bottom: em(30);
      }
    }
  }

  .billboard & {
    .cta-btn--white {
      color: $blue;

      &:hover {
        color: #fff;
      }
    }
  }
}
