$menu-width: 100%;

body.menu-open {
  position: fixed;

  .site-header {
    .hs-menu-depth-1 {
      &:last-child {
        opacity: 0;
      }
    }
  }
}

.menu-trigger__container {
  cursor: pointer;
  margin-left: 12px;
  text-align: center;
  width: 32px;

  button > span {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
    }
  }

  &:not(.open) {
    .on {
      display: none;
    }
  }

  &.open {
    .off {
      display: none;
    }
  }

  @media (min-width: 992px) {
    display: none;
    visibility: hidden;
  }
}

.menu-trigger__button {
  outline: none;
  border: 0;
  background: none;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  text-align: center;
  padding: 0 !important;

  span.label {
    color: #fff;
    margin-top: 5px;
    text-align: center;
    font-size: 8px;

    &:after {
      content: 'Menu';
    }

    .open & {
      &:after {
        content: 'Close';
      }
    }
  }

  span.icon {
    width: 25px !important;

    &,
    &:before,
    &:after {
      width: 25px;
    }

    @include burger(25px, 3px, 4px, #fff, 3px, 0.3s);

    .open & {
      @include burger-to-cross;
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  transition: .3s;
  z-index: 9;
  background-color: #000;
  padding: 1em;
  width: $menu-width;
  height: 100%;
  overflow: scroll;
  text-align: center;

  .inner {
    padding-top: em(150) !important;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    display: block;
  }

  &__main {
    .hs-menu-children-wrapper {
      display: none;
      visibility: hidden;
    }

    .hs-menu-depth-1:last-child {
      display: none;
      visibility: hidden;
    }
  }

  .hs-menu-depth-1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    text-transform: uppercase;

    > a {
      color: #ffffff;
      padding: em(5) 0;
    }
  }

  &__secondary {
    margin-top: em(75);

    > a {
      @extend %h6;

      font-size: 14px;
      letter-spacing: 2px;
    }

    .hs-menu-children-wrapper {
      font-size: 36px;
      font-weight: 700;
      text-transform: uppercase;

      a {
        padding: em(3) 0;
      }
    }
  }

  &__close-btn {
    position: relative;
  }

  &:not(.open) {
    display: none;
    visibility: hidden;
  }
}
