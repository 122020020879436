.blog-listing {
  &__grid {
    @extend %nested-layout__grid;

    flex-wrap: wrap;
  }

  &__card {
    > .inner {
      padding: em(10);
    }
  }

  &__column {
    @media (min-width: 768px) {
      &, > div {
        display: flex;

        > .inner {
          display: flex;
        }
      }
    }

    > .inner {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: 768px) {
      width: 33.3%;
    }
  }

  &__content {
    .hs_cos_wrapper_type_rich_text:not(:empty) {
      @extend %module--small;

      padding-bottom: 0;
      display: block;
    }
  }

  &__pagination {
    display: flex;
    position: relative;
    justify-content: center;
    margin-top: em(50);

    a {
      @extend %cta-btn;

      @extend %cta-btn--ghost;

      @extend %cta-btn--small;

      margin: 0 em(10);
      font-weight: 700;
      border-radius: 4px;

      &[class*='prev'] {
        &:before {
          content: '';
          display: inline-block;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-right: 6px solid $gray-dark;
          margin-right: 10px;
          transition: .3s;
        }

        &:hover {
          &:before {
            border-right: 6px solid #fff;
          }
        }
      }

      &[class*='next'] {
        &:after {
          content: '';
          display: inline-block;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 6px solid $gray-dark;
          margin-left: 10px;
          transition: .3s;
        }

        &:hover {
          &:after {
            border-left: 6px solid #fff;
          }
        }
      }
    }
  }
}
