.site-footer {
  background-color: #000;
  padding-top: em(30);
  padding-bottom: em(30);
  text-align: center;

  > .inner {
    @extend %constrain;

    position: relative;

    @media (min-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
    }
  }

  &__main-menu {
    .hs-menu-depth-1 {
      font-size: 14px;

      > a {
        color: #fff;
        padding: em(10);
      }
    }

    a {
      display: block;
    }
  }

  &__copyright {
    font-size: 14px;
    color: $gray-5;

    @media (min-width: 600px) {
      margin-left: em(20);
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: center;

      > footer > span {
        display: block;
        margin-right: em(5);
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;

    > span {
      @media (max-width: 991px) {
        display: block;
        margin-bottom: em(12);
      }
    }
  }
}
