.blog-author {
  background-color: $gray-lighter;
  border: none;
  margin-bottom: em(30);
  padding: em(40);
  text-align: center;

  @media (min-width: 768px) {
    display: flex;
    text-align: left;
    justify-content: space-between;
  }

  &__photo {
    display: inline-block;
    margin-bottom: em(20);
    width: 25%;

    img {
      margin-bottom: 0;
      width: em(150);
      height: auto;
    }

    @media (min-width: 768px) {
      width: 25%;

      img {
        display: block;
        margin-right: em(20);
        max-width: none;
        width: 80%;
      }
    }
  }

  &__bio {
    @media (min-width: 768px) {
      width: 75%;
    }
  }

  &__info {
    margin-bottom: em(10);

    h4 {
      margin: 0;

      &, a {
        color: $gray-darker;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  &__name {
    @media (min-width: 768px) {
      margin-right: em(15);
    }
  }

  &__social-links {
    padding: 0;
  }
}
