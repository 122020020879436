%module,
.module {
  position: relative;

  &__form {
    margin-top: em(60) !important;
  }

  &--spacer {
    padding-right: 0;
    padding-left: 0;
  }

  &--grad-black-to-gray {
    background-image: linear-gradient(180deg, #000000 0%, $gray-darker 100%);
  }

  &--black {
    background-color: #000;

    h1, h2, h3, h4, p {
      color: #fff;
    }

    h5 {
      color: $orange;
    }
  }

  &--dark-gray,
  &--gray-darker {
    background-color: $gray-darker;

    h1, h2, h3, h4, p {
      color: #fff;
    }
  }

  &--gray {
    background-color: $gray-light;
  }

  &--small {
    padding-top: em(60);
    padding-bottom: em(60);
  }

  &--medium {
    padding-top: em(100);
    padding-bottom: em(100);
  }

  &--large {
    padding-top: em(150);
    padding-bottom: em(150);

    @media (max-width: 479px) {
      padding-top: em(75);
      padding-bottom: em(75);
    }
  }

  &--xl {
    padding-top: em(250);
    padding-bottom: em(250);
  }

  &--no-top {
    padding-top: 0;
  }

  &--no-bottom {
    padding-bottom: 0;
  }

  &--border-top {
    border-top: 2px solid $gray-light;
  }

  &--border-bottom {
    border-bottom: 2px solid $gray-light;
  }

  &__section-header {
    padding-bottom: em(40);
  }

  &__section-body {}

  &__section-footer {}
}
