h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

h1,
%h1 {
  color: #000;
  margin-bottom: em(10, 36);
  line-height: (38 / 36);
  font-size: em(36, 10);
  text-transform: uppercase;
  font-weight: 700;

  @media (min-width: 992px) {
    margin-bottom: em(10, 51);
    line-height: (54 / 51);
    font-size: em(51, 14);
  }

  @media (min-width: 1200px) {
    margin-bottom: em(10, 81);
    line-height: (85 / 81);
    font-size: em(85);
  }
}

h2,
%h2 {
  color: $gray-darker;
  margin-bottom: em(10, 30);
  line-height: (36 / 30);
  font-size: em(30, 10);
  text-transform: uppercase;
  font-weight: 700;

  @media (min-width: 992px) {
    margin-bottom: em(10, 42);
    line-height: (48 / 42);
    font-size: em(42, 18);
  }

  @media (min-width: 1200px) {
    margin-bottom: em(10, 55);
    line-height: (60 / 55);
    font-size: em(55);
  }
}

h3,
%h3 {
  color: $dusty-navy;
  margin-bottom: em(10, 38);
  line-height: (42 / 38);
  font-size: em(38);
  text-transform: uppercase;
  font-weight: 700;
}

h4,
%h4 {
  color: $dusty-navy;
  margin-bottom: em(10, 28);
  line-height: (32 / 28);
  font-size: em(28);
  font-weight: 600;
}

h5,
%h5 {
  color: $gray;
  margin-bottom: em(8, 22);
  line-height: (28 / 22);
  font-size: em(22);
  text-transform: uppercase;
  color: $gray;
  letter-spacing: 2px;
}

h6,
%h6 {
  color: $gray;
  margin-bottom: em(8, 16);
  line-height: (20 / 16);
  font-size: em(16);
  text-transform: uppercase;
}

p,
%p {
  margin-bottom: em(15);
  line-height: 1.7;
  font-size: em(22);
  line-height: (32 / 22);

  &.large {
    font-size: em(30);
    line-height: (40 / 30);
  }
}

ul,
ol {
  &.inline {
    list-style: none;

    li {
      display: inline-block;
      margin-right: em(10);
    }
  }

  &.cta-set {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;

    > li {
      margin: 0 em(10);
    }
  }
}

strong {}

a {
  text-decoration: none;
  color: $primary;
}

blockquote {
  color: $dusty-navy;
  font-size: em(36);
  font-weight: 400;
  line-height: (50 / 36);
}
