.floating-cta {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $gray-darker;
  z-index: 12;
  transition: .3s;

  .inner {
    padding: em(28) 0;

    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__content {
    h1, h2, h3, h4, h5, h6, p {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__button {
    @media (min-width: 768px) {
      margin-left: em(20);
    }
  }

  * {
    color: #fff;
  }

  p {
    margin-bottom: 0;
  }

  &:not(.active) {
    transform: translateY(100%);
  }
}
