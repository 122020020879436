%card,
.card {
  border: 1px solid $gray-light;
  overflow: hidden;
  border-radius: 4px;

  .inner {
    padding: em(30);
  }
}
