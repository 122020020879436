%nested-layout,
.nested-layout {
  &__grid {
    &,
    > span {
      @media (min-width: 768px) {
        display: flex;
        width: 100%;
      }
    }
  }

  &--main {
    &,
    > span {
      @media (min-width: 768px) {
        flex-wrap: wrap;
      }
    }
  }

  &--r-to-l {
    &,
    > span {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }
  }

  &__photo {
    img {
      width: 100%;
    }
  }

  &__column {
    position: relative;

    .inner {
      padding-left: em(10);
      padding-right: em(10);
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      &:not(:last-child) {
        margin-bottom: em(50);
      }
    }
  }

  @media (min-width: 768px) {
    &--one-col {
      span > div,
      .nested-layout__column {
        width: 100%;
      }
    }

    &--two-col {
      span > div,
      .nested-layout__column {
        width: 50%;
      }
    }

    &--two-col-left {
      display: flex;
      justify-content: stretch;

      > div {
        &:first-child {
          width: 33.3%;
        }

        &:last-child {
          width: 66.666%;
        }
      }
    }

    &--three-col {
      span > div,
      .nested-layout__column {
        width: 33.33%;
      }
    }

    &--four-col {
      span > div,
      .nested-layout__column {
        width: 25%;
      }
    }
  }

  &--hide {
    display: none;
    visibility: hidden;
  }
}

.column {
  &__content {
    h3 {
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $gray-darker;
      font-size: em(22);
      margin-bottom: em(15);
    }

    h5 {
      margin-bottom: em(20);
    }

    @media (max-width: 767px) {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__icon {
    margin-top: em(25);
    margin-bottom: em(25);
  }
}
