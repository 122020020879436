.cta-btn,
%cta-btn {
  display: inline-block;
  position: relative;
  transition: .3s ease-in-out;
  border: 0;
  background-color: $light-orange;
  padding: em(20) em(45);
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 2px;
  letter-spacing: 1px;
  text-decoration: none;

  span {
    font-size: inherit;
  }

  @media (min-width: 768px) {
    font-size: 17px;
  }

  &:hover {
    background-color: $dark-orange;

    &, span {
      color: #fff;
    }
  }

  &--orange {
    background-color: $orange;

    &, span {
      color: #fff;
    }

    &:hover {
      background-color: $dark-orange;
    }
  }

  &--blue {
    background-color: $blue;

    &, span {
      color: #fff;
    }

    &:hover {
      background-color: $dark-blue;
    }
  }

  &--gray {
    background-color: $gray-darker;

    &, span {
      color: #fff;
    }

    &:hover {
      background-color: $gray-5;
    }
  }

  &--white {
    background-color: #fff;

    &, span {
      color: $blue;
    }

    &:hover {
      background-color: $blue;

      &, span {
        color: #fff;
      }
    }
  }

  &--ghost {
    border: 1px solid $gray;
    background-color: #fff;

    &, span {
      color: $gray-dark;
    }

    &:hover {
      background-color: $gray-dark;

      &, span {
        color: #fff;
      }
    }
  }

  &--icon {
    .inner {
      display: flex;
      align-items: center;
    }

    span {
      display: inline-block;
    }

    .icon {
      margin-right: em(10);

      svg {
        width: em(30);
        height: em(30);

        @media (min-width: 992px) {
          width: em(50);
          height: em(50);
        }
      }
    }
  }

  &--arrow {
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #fff;
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle;
    }
  }

  &--small {
    padding: em(18) em(25);

    &, span {
      font-size: 14px;
    }
  }

  &--large {}

  &--xl {}
}
