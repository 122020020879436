%dynamic-menu {
  .hs-menu-depth-1:not(.active-branch) {
    display: none;
    visibility: hidden;
  }
}

.sidebar {
  > div:not(:last-child) {
    margin-bottom: 2em;
  }
}

.sidebar-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .hs-menu-children-wrapper {
    margin-left: em(10);
    font-size: em(14);
  }

  &--static {}

  &--dynamic {
    @extend %dynamic-menu;
  }
}
