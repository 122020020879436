.compare {
  .module__section-header {
    padding-bottom: em(60);

    h1, h2, h3 {
      color: #fff;
    }

    p {
      color: $gray;
    }
  }

  .nested-layout__grid {
    justify-content: center;
  }

  .nested-layout__column {
    padding: em(80);

    * {
      color: #fff;
    }

    &:first-child {
      background-color: $gray-2;
      margin-bottom: em(12);
      position: relative;
      overflow: visible;
      z-index: 2;

      &:after {
        content: 'VS';
        display: none;
        background-color: $orange;
        position: absolute;
        z-index: 9;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        line-height: 60px;
        color: #fff;
        box-shadow: 0 0 0 7px $gray-darker;
      }

      @media (max-width: 767px) {
        padding-bottom: em(100);

        &:after {
          display: inline-block;
          left: 50%;
          transform: translateX(-50%);
          font-size: 20px;
          bottom: -30px;
        }
      }

      @media (min-width: 768px) {
        margin-bottom: 0;
        border-top: em(30) solid $gray-darker;
        border-bottom: em(30) solid $gray-darker;

        &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          border-right: 7px solid $gray-darker;
        }

        &:after {
          display: inline-block;
          transform: translateY(-50%);
          top: 50%;
          right: -30px;
          font-size: 18px;
        }
      }

      @media (min-width: 992px) {
        &:after {
          right: -43px;
          height: 85px;
          width: 85px;
          line-height: 85px;
          font-size: 30px;
        }
      }
    }

    &:last-child {
      background-color: $gray-3;
      position: relative;
      z-index: 1;

      @media (max-width: 767px) {
        padding-top: em(100);
      }
    }

    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      line-height: 1.8;
      font-size: em(22);
      margin-bottom: 0;
    }

    img {
      margin-top: em(40);
    }

    @media (min-width: 768px) {
      padding: em(70) em(90);
    }
  }
}
