.agencies-map {
  background-color: $gray-lighter;
  position: relative;
  overflow: hidden;

  &__underlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
  }

  > .inner {
    @extend %module--xl;
  }

  &__content {
    position: relative;

    .inner {
      background-color: #fff;
      box-shadow: 0 16px 24px rgba(0, 0, 0, 0.28);
      border-radius: 3px;
      padding: em(40);
      max-width: em(600);
      margin-left: auto;
      margin-right: auto;
      z-index: 1;
      position: relative;
    }
  }

  &__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    background-position: center center;

    > div {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      display: none;
    }
  }
}
