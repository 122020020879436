.billboard {
  position: relative;
  background-color: $gray-light;
  overflow: hidden;

  > [class*='constrain'] {
    padding-top: em(70);
    padding-bottom: em(70);
  }

  &__underlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center 15%;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
  }

  &__content {
    position: relative;

    p {
      @extend %p.large;
    }

    a {
      font-weight: 700;

      &:not(.cta-btn) {
        @media (max-width: 479px) {
          display: block;
          color: #fff !important;
        }
      }
    }

    .cta-set {
      margin-top: em(50);
    }
  }

  &__video-content {}

  &__video-embed {
    .module__section-header {}
  }

  &__scroll {
    background: none;
    border: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: em(60);
    cursor: pointer;
    outline: none;

    @media (max-width: 479px) {
      display: none;
    }
  }

  &--small {
    @media (min-width: 480px) {
      > [class*='constrain'] {
        padding-top: em(130) !important;
        padding-bottom: em(130) !important;
      }
    }
  }

  &--medium {
    @media (min-width: 480px) {
      > [class*='constrain'] {
        padding-top: em(200) !important;
        padding-bottom: em(200) !important;
      }
    }
  }

  &--large {
    @media (min-width: 480px) {
      > [class*='constrain'] {
        padding-top: em(250) !important;
        padding-bottom: em(250) !important;
      }
    }
  }

  &--full {
    height: 100vh;

    @media (min-height: 800px) {
      .billboard__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  &--has-bg {
    .billboard__content {
      h1, h2, h3, h4, a {
        color: #fff;
      }

      p {
        color: $gray-7;

        &.large {
          color: #fff;
        }
      }

      a:not(.cta-btn) {
        color: $orange;
      }
    }
  }
}
