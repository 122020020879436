.cta-bar {
  position: relative;
  background-color: $gray-light;
  overflow: hidden;

  [class*='constrain'] {
    padding-top: em(50);
    padding-bottom: em(50);
  }

  &__underlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center 15%;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
  }

  &__content {
    position: relative;

    .cta-btn {
      margin-top: em(30);
    }
  }

  &--small {
    @media (min-width: 480px) {
      [class*='constrain'] {
        padding-top: em(130) !important;
        padding-bottom: em(130) !important;
      }
    }
  }

  &--medium {
    @media (min-width: 480px) {
      [class*='constrain'] {
        padding-top: em(200) !important;
        padding-bottom: em(200) !important;
      }
    }
  }

  &--large {
    @media (min-width: 480px) {
      [class*='constrain'] {
        padding-top: em(250) !important;
        padding-bottom: em(250) !important;
      }
    }
  }

  &--has-bg {
    .cta-bar__content {
      h1, h2, h3, h4, a {
        color: #fff;
      }

      p {
        color: $gray-7;

        &.large {
          color: #fff;
        }
      }

      a:not(.cta-btn) {
        color: $orange;
      }
    }
  }
}
