$black: #000;
$light-orange: #f7751f;
$orange: #f66c0f;
$dark-orange: #bf5d17;
$light-blue: #1da1db;
$blue: #1b96cc;
$dark-blue: #1780ae;
$dusty-navy: #2b373c;
$navy: #262b39;
$teal: #0f434c;

$gray-1: #2e2e2e;
$gray-2: #3b3f3f;
$gray-3: #4f4f4f;
$gray-4: #555;
$gray-5: #797979;
$gray-6: #838383;
$gray-7: #999;
$gray-8: #c3c4c5;
$gray-9: #d1d1d1;
$gray-10: #e4e5e6;
$gray-11: #f9f8f7;

$gray-lighter: $gray-11;
$gray-light: $gray-10;
$gray: $gray-7;
$gray-dark: $gray-2;
$gray-darker: $gray-1;
$primary: $orange;

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  color: $gray-4;
  text-align: center;
  font-size: 10px;

  &, * {
    font-family: 'proxima-nova', sans-serif;
  }

  @media (min-width: 768px) {
    font-size: 12px;
  }

  @media (min-width: 992px) {
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
  }
}

hr {
  margin-top: em(30);
  margin-bottom: em(30);
}

.section-divider {
  figure {
    display: block;
    border-top: 2px solid $gray-light;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.row-fluid [class*='span'] {
  min-height: 0 !important;
}

.overlay,
%overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &--light {
    background: rgba(black, 0.2);
  }

  &--medium {
    background: rgba(black, 0.4);
  }

  &--dark {
    background: rgba(black, 0.6);
  }

  &--darker {
    background: rgba(black, 0.8);
  }
}

.constrain,
%constrain {
  float: none !important;
  margin: auto !important;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 1280px;

  &--padded {
    max-width: 1100px;
  }

  &--narrow {
    max-width: 940px;
  }

  &--narrower {
    max-width: 760px;
  }
}

.constrain {
  .page--padded & {
    @extend %constrain--padded;
  }

  .page--narrow & {
    @extend %constrain--narrow;
  }

  .page--narrower & {
    @extend %constrain--narrower;
  }
}

.primary-content-container {
  text-align: left;

  &--padding-standard {
    padding-top: em(50);
    padding-bottom: em(50);
  }

  &--padding-large {
    padding-top: em(80);
    padding-bottom: em(80);
  }
}

.primary-content {
  &--two-col {
    @media (min-width: 768px) {
      > .row-fluid-wrapper > .row-fluid {
        display: flex;
        justify-content: space-between;

        [class*='primary-content'] {
          width: 48%;
        }
      }
    }
  }

  &--two-col-right {
    @media (min-width: 768px) {
      > .row-fluid-wrapper > .row-fluid {
        display: flex;
        justify-content: space-between;
      }

      .primary-content__left-col {
        width: 71% !important;
      }

      .primary-content__right-col {
        width: 25% !important;
      }
    }
  }

  &--two-col-left {
    @media (min-width: 768px) {
      > .row-fluid-wrapper > .row-fluid {
        flex-direction: row-reverse;
      }

      .primary-content__left-col {
        width: 71% !important;
      }

      .primary-content__right-col {
        width: 25% !important;
      }
    }
  }
}

.custom-rich-text {
  img {
    max-width: 100%;
    height: auto;
  }
}

a[title='Sumo'] {
  display: none;
  visibility: hidden;
}

.embed-responsive,
.video-container,
.responsive-video {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 75%;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}
