.blog-post {
  &__header {
    background-color: $gray-dark;
    position: relative;
    text-align: center;
    margin-bottom: em(50);

    &__underlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      z-index: 0;
    }

    &__overlay {
      @extend %overlay;

      &--light {}
    }

    &__content {
      position: relative;
      z-index: 1;
      padding-top: em(250);
      padding-bottom: em(250);
      padding-left: em(50) !important;
      padding-right: em(50) !important;

      h1, h2 {
        color: #fff;
      }
    }

    &__topics {
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: em(10);

      &, a {
        color: $gray;
      }
    }

    &__byline {
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $gray;
      margin-top: em(30);

      a {
        color: #fff;
      }
    }

    &__share {}
  }

  &__share-icons {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;

      > li {
        display: flex;
        border-radius: 3px;
        margin: 0 4px;
        transition: .3s;
        overflow: hidden;

        a {
          transition: .3s;

          @media (max-width: 767px) {
            span.label {
              display: inline-block;
              font-size: 11px;
              line-height: 15px !important;
            }
          }
        }

        &.facebook {
          > a {
            background-color: #3a5a97;
          }
        }

        &.twitter {
          > a {
            background-color: #59aeec;
          }
        }

        &.linkedin {
          > a {
            background-color: #1179b4;
          }
        }

        &.email {
          > a {
            background-color: #4d93e0;
          }
        }
      }
    }

    a {
      display: block;
      padding: em(8);
      color: #fff;

      > .inner {
        display: flex;
        align-items: center;
      }

      .icon {
        margin-right: em(8);

        svg {
          width: 15px;
          height: 15px;
        }
      }

      .label {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
      }
    }
  }

  &__extra {
    border-top: 1px solid $gray-light;
    padding: em(30) 0;
    text-align: center;

    h3 {}

    ul {
      margin: 0;
      padding: 0;
    }
  }

  &__topics {
    a {
      color: $gray-dark;

      &:hover {
        color: $orange;
      }
    }
  }

  &__cta {
    margin: em(30) 0;
    text-align: center;

    img {
      width: 100%;
    }
  }

  &__share {
    border-top: 1px solid $gray-light;
    padding: em(40) 0;
  }

  &__comments {
    padding: em(30) 0;

    &:before {
      @extend %h3;

      display: block;
      margin-bottom: em(15);
      content: 'Comments';
    }

    #comment-form {
      border: 1px solid $gray-lighter;
      padding: em(50);

      &:before {
        @extend %h3;

        display: block;
        margin-bottom: em(10);
        content: 'Leave a Comment';
      }

      .actions {
        text-align: left;
      }

      input:not([type='submit']),
      textarea {
        padding: em(15) !important;
      }
    }

    #captcha_wrapper {
      transform: scale(0.9);
      width: 100%;
      overflow: hidden;
    }
  }
}
