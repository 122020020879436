.testimonials {
  .module__section-header {
    padding-bottom: em(60);
  }

  &__slides {
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.28);
  }

  &__thumbs {
    display: flex;
    justify-content: center;
    padding: em(40) 0;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
      padding: em(30) 0;
    }
  }
}

.testimonial-slide {
  background-color: $gray-dark;
  padding: em(40) em(20);

  @media (min-width: 768px) {
    padding: em(80) em(50);
  }

  blockquote {
    padding: 0;
    margin: 0;
  }

  > .inner {
    @extend %constrain;

    @extend %constrain--padded;
  }

  &__content {
    * {
      color: #fff;
    }

    h5 {
      color: $orange;
      margin-bottom: em(30);
    }
  }

  &__pic {
    @media (max-width: 767px) {
      margin-bottom: em(20);
    }

    @media (min-width: 768px) {
      margin-right: em(20);
    }
  }

  &__info {
    margin-top: em(40);

    &.has-pic {
      @media (min-width: 600px) {
        display: flex;
        text-align: left;
        justify-content: center;
        align-items: center;
      }
    }

    h6 {
      color: #fff;
      letter-spacing: 2px;
    }

    h4 {
      font-size: em(20);
      font-weight: 400;
      color: #fff;
    }
  }

  &__name {}

  &:not(.active) {
    display: none;
    visibility: hidden;
  }
}

.testimonial-trigger {
  display: block;
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 33.3%;
  opacity: .5;

  > span {
    display: block;
    margin: em(5) !important;
  }

  img {
    width: 100%;
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: em(15) solid transparent;
    border-right: em(15) solid transparent;
    border-top: em(20) solid $gray-dark;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -(em(30));
    transition: .3s;
    opacity: 0;
  }

  &.active {
    opacity: 1;

    @media (min-width: 768px) {
      &:before {
        opacity: 1;
      }
    }
  }

  @media (min-width: 768px) {
    padding-top: 30px;
    width: auto;
  }
}
