.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba($gray-darker, 0.95);
  z-index: 3;

  &:not(.active) {
    display: none;
    visibility: hidden;
  }

  .inner {
    padding: em(30);
  }

  .content {
    width: 90%;
    max-width: em(800);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    @media (min-width: 800px) {
      min-width: em(800);
    }
  }

  .content-inner {
    background-color: #fff;
  }

  &__form {
    background: #fff;
    padding: em(20) em(30);

    form {
      display: flex;
      align-items: center;

      > div {
        flex-grow: 1;
      }
    }

    svg {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: em(10);
    }

    input {
      background: transparent;
      color: $gray-darker !important;
      font-size: em(28);
      border: none;
    }
  }

  &__close-btn {
    @extend %h6;

    text-shadow: none;
    cursor: pointer;
    outline: none;
    top: -30px;
    right: 0;
    position: absolute;
    color: #fff;
    background: transparent;
    border: none;

    svg {
      margin-left: 10px;
    }
  }
}
