.instagram-feed {
  .nested-layout__grid {
    justify-content: stretch;
  }

  .nested-layout__column {
    margin: 0 !important;
  }

  &__content {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      z-index: 1;
      opacity: .4;
    }

    .insta-feed {
      > a {
        position: relative;
        background-size: cover;
        display: block;
        width: 100% !important;
        height: auto !important;
      }
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 2;

      * {
        color: #fff;
      }

      .cta-btn {
        margin-top: em(20);
      }
    }
  }

  &__feed {
    .insta-feed {
      > a {
        width: 25%;

        &:first-child {
          display: none;
          visibility: hidden;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .nested-layout__grid {
      > div {
        display: flex !important;
      }
    }
  }
}

.insta-feed {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  width: 100%;

  > a {
    background-color: $gray;
    display: inline-block;
    position: relative;
    background-size: cover;
    background-position: center center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: .2;
      transition: .3s;
    }

    > img {}

    .square {
      width: em(10);
      height: em(10);
    }

    &:hover {
      &:before {
        opacity: 0;
      }
    }
  }

  span {
    display: block;
  }

  .container {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
  }

  &.insta-loaded {
    height: auto;
  }
}
