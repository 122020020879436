.steps {
  background-color: $gray-darker;

  h1, h2, h3, h4, h5, h6, p {
    color: #fff;
  }

  .module__section-header {
    padding-bottom: em(50);
  }

  .nested-layout__grid {
    .nested-layout__column {
      position: relative;
      padding-left: em(30);
      padding-right: em(30);
      padding-top: em(85);
      padding-bottom: em(100);

      &:before {
        content: '';
        display: inline-block;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        line-height: 55px;
        box-shadow: 0 0 0 7px $gray-darker;
        font-size: 28px;
        color: #fff;
        font-weight: 700;
        text-align: center;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        z-index: 5;
      }

      &:nth-child(1) {
        background-color: $blue;

        &:before {
          content: '1';
          background-color: $blue;
        }

        .column__link {
          a:hover {
            background-color: $dark-blue !important;
          }
        }
      }

      &:nth-child(2) {
        background-color: $gray-2;

        &:before {
          content: '2';
          background-color: $gray-2;
        }
      }

      &:nth-child(3) {
        background-color: $orange;

        &:before {
          content: '3';
          background-color: $orange;
        }
      }

      &:not(:last-child) {
        &:after {
          content: '';
          display: block;
          position: absolute;

          @media (max-width: 768px) {
            bottom: 0;
            left: 0;
            width: 100%;
            border-top: 7px solid $gray-darker;
          }

          @media (min-width: 768px) {
            top: 0;
            right: 0;
            height: 100%;
            border-right: 7px solid $gray-darker;
          }
        }
      }

      @media (min-width: 768px) {
        padding-bottom: em(60);
      }
    }
  }

  .nested-layout__column {
    @media (min-width: 768px) {
      &,
      .inner {
        display: flex;
        width: 100%;
      }

      .inner {
        flex-direction: column;
      }

      .column__content {
        flex-grow: 1;
      }
    }
  }

  .column__content {
    margin-bottom: em(40);

    h3 {
      margin-bottom: em(10);
    }
  }

  .column__link {
    a {
      @extend %cta-btn;

      @extend %cta-btn--white;

      @extend %cta-btn--small;

      font-weight: 700;
    }
  }
}
