.list-layout {
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      > li {
        padding: em(10);
      }
    }
  }

  @media (min-width: 768px) {
    &__one-col {
      ul > li {
        width: 100%;
        flex-basis: 100%;
      }
    }

    &__two-col,
    &__four-col,
    &__five-col,
    &__six-col {
      ul > li {
        width: calc(50% - 10px);
      }
    }

    &__three-col {
      ul > li {
        width: calc(33% - 10px);
      }
    }
  }

  @media (min-width: 992px) {
    &__four-col {
      ul > li {
        width: calc(25% - 10px);
      }
    }

    &__five-col {
      ul > li {
        width: calc(20% - 10px);
      }
    }

    &__six-col {
      ul > li {
        width: calc(17% - 10px);
      }
    }
  }

  &--logo {
    .module__section-header {
      padding-bottom: 0;
    }

    ul {
      > li {
        padding-top: em(30);
        padding-bottom: em(30);

        img {
          width: 100%;
        }
      }

      @media (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > li {
          width: 45% !important;
          padding-left: em(10);
          padding-right: em(10);
        }
      }
    }
  }
}
