.system {
  .billboard {
    h1 {
      font-size: em(58);
    }
  }

  #hs-search-module {
    margin-top: em(20);
  }

  #email-prefs-form,
  #hs_cos_wrapper_password_prompt,
  #hs_cos_wrapper_email_simple_subscription {
    input[type='submit'] {
      @extend %cta-btn;

      @extend %cta-btn--blue;
    }
  }

  .hs-search-module {
    .input {
      display: flex;
      margin-top: em(20);

      @media (max-width: 479px) {
        flex-direction: column;
      }
    }

    .hs-button.primary {
      @extend %cta-btn;

      @extend %cta-btn--blue;

      flex-shrink: 0;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;

      @media (min-width: 480px) {
        margin-top: 0 !important;
      }

      &:hover {
        background-color: #0466bc !important;
      }
    }

    input[type='text'] {
      font-size: em(20);
    }
  }

  .hs_cos_wrapper_type_email_simple_subscription {
    h1 {
      @extend %h2;
    }

    h3 {
      @extend %h4;
    }

    input[type='submit'] {
      margin-top: em(15, 13);
    }
  }

  .hs_cos_wrapper_type_email_subscriptions {
    h1 {
      @extend %h2;

      text-transform: none;
    }

    h2 {
      @extend %h4;

      font-weight: 400;
    }

    p {
      font-size: em(18);
      color: $gray-darker;

      &.header {
        margin-left: 0;
      }
    }

    label[for='globalunsub'] {
      display: flex;
      align-items: center;
      margin-left: 0;
      font-size: em(16);
      margin-top: em(10);
      margin-bottom: em(15);

      input {
        margin-right: em(5);
      }

      span {
        font-size: em(14);
      }
    }
  }

  .hs_cos_wrapper_type_email_subscriptions_confirmation {
    h2 {
      @extend %h2;
    }

    p,
    .success {
      font-size: em(18);
      color: $gray-darker;
    }
  }

  input[type='password'] {
    height: auto !important;
    margin-top: 0 !important;
    margin-right: 1em;
    padding-left: em(10);

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: em(30);
    }

    @media (min-width: 768px) {
      width: calc(100% - 30em);
    }
  }

  #hs_cos_wrapper_password_prompt {
    text-align: center;
    display: block;

    form {
      display: flex;
      justify-content: center;
    }
  }
}
