div.hs_cos_wrapper_type_form {
  text-align: left;

  .flex-bottom-full & {
    @extend %module--large;

    background-color: $gray-light;

    h3 {
      @extend %constrain;

      @extend %constrain--narrow;
    }

    form {
      @extend %constrain;

      @extend %constrain--narrower;
    }
  }
}

input[type='text'],
input[type='email'],
input[type='phone'],
input[type='tel'],
input[type='number'],
input[type='file'],
textarea,
.hs_dropdown_select .input,
.styled-select {
  outline: none;
  padding: 20px !important;
  width: 100%;
  font-size: em(16);
  box-sizing: border-box !important;
  box-shadow: 0 2px 0 $gray-9;
  border-radius: 2px;
  border: 1px solid $gray-10;
  background-color: $gray-11;

  &.invalid,
  &.error {
    border-color: transparent !important;

    //box-shadow: 0 2px 0 red !important;
 
  }

  &:focus {
    background-color: #fff;
    box-shadow: 0 2px 0 $blue !important;
    border-color: transparent !important;
  }
}

input[type='submit'] {
  cursor: pointer;
}

textarea {
  max-width: 100% !important;
}

input[type='file'] {
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 1ex;
  font-size: 1em;
}

.styled-select {
  position: relative;
  overflow: hidden;

  &:after {
    content: url(https://cdn2.hubspot.net/hubfs/530635/assets/images/ui/arrow-down.svg);
    position: absolute;
    right: 20px;
    top: 45%;
    transform: scale(-1);
  }
}

select {
  font-size: inherit;
  -webkit-appearance: none;

  .styled-select & {
    background: transparent;
    border: 0 !important;
    padding: 0 !important;
    outline: 0 !important;
    width: 120%;
  }
}

input[type='submit'] {}

.form-title {
  &:not(:empty) {
    margin-bottom: 1em;
  }
}

.hs-form {
  fieldset {
    max-width: none;
  }

  .hs-form-field {
    margin-bottom: em(25) !important;
    vertical-align: top;

    > label {
      display: block;
      margin-bottom: em(10);
      font-size: em(16);
      font-weight: 400;
      color: $gray-3;
    }
  }

  .input {
    width: 100%;
  }

  .hs_dropdown_select {
    position: relative;

    .input {
      position: relative;
      overflow: hidden;

      select {
        outline: none;
        border: 0;
        background: transparent;
        width: 103% !important;
        font-size: inherit;
        appearance: none;
        box-sizing: border-box;
      }

      &:before {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        border-width: em(7) em(5) 0 em(5);
        border-style: solid;
        border-color: $primary transparent transparent;
        width: 0;
        height: 0;
        content: '';
      }
    }
  }

  .hs-datepicker {
    transform: translateY(-1px);

    .pika {
      &-single {
        border: 1px solid $gray;
        box-shadow: none;
      }

      &-label {
        color: $gray-darker;
        font-size: 1em;
        font-weight: normal;
      }

      &-lendar {
        margin: 0;
        padding: 10px;
      }

      &-button {
        border-radius: 0;
        box-shadow: none;
        padding: 10px 5px;
        text-align: center;
        font-size: em(13);

        &:hover {
          border-radius: 0 !important;
          background-color: rgba($primary, 0.75) !important;
        }
      }

      &-table {
        th {
          font-size: em(13);
        }

        td {
          &:not(.is-today):not(.is-selected) .pika-button {
            background-color: #fff;
            color: $gray-dark;
          }

          &.is-today .pika-button {
            background-color: $gray-lighter;
            color: $primary;
          }
        }
      }
    }

    .is-selected {
      .pika-button {
        background-color: $primary;
        font-weight: normal;

        &:hover {
          background-color: $primary !important;
        }
      }
    }

    abbr {
      border-bottom: 0;
      text-decoration: none;
      color: $gray-darker;
      font-weight: normal;
    }
  }

  .hs-error-msgs {
    color: red;
    font-size: em(14);
  }

  ul.inputs-list {
    margin-top: 10px;
    margin-left: 0;
    padding-left: 0;
    list-style: none;

    li {
      padding-left: 0;
    }

    input {
      margin-right: em(10);
    }
  }

  .hs-button {
    @extend %cta-btn;

    @extend %cta-btn--blue;

    @extend %cta-btn--small;

    background-image: none !important;
    outline: none !important;
    border: none !important;
  }
}

input[type='submit'] {
  @extend %cta-btn;

  @extend %cta-btn--blue;

  @extend %cta-btn--small;

  background-image: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

// Hubspot Overrides
fieldset {
  .hs-input:not([type='checkbox']):not([type='radio']):not(select) {
    width: 100% !important;
  }

  > div {
    float: none !important;
  }

  @media (min-width: 768px) {
    &.form-columns-2 {
      text-align: left;

      > div:not(.hs-richtext) {
        display: inline-block;
        float: none !important;
        width: 49% !important;

        &:last-child {
          float: right !important;
        }
      }
    }

    &.form-columns-3 {
      text-align: left;

      > div:not(.hs-richtext) {
        display: inline-block;
        margin: 0 1%;
        width: 32% !important;
        text-align: left;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          float: right !important;
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 767px) {
    > div {
      display: block !important;
      width: 100% !important;
    }
  }
}
