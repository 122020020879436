.blog-header {
  display: none;
  visibility: hidden;
  background-color: $gray-light;
  text-align: left;

  body.blog & {
    display: block;
    visibility: visible;
  }

  h1, h2 {
    font-size: em(28);
    font-weight: 700;
    margin: 0;
    text-transform: none;
  }

  > .inner {
    padding-top: em(18);
    padding-bottom: em(18);

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    h1, h2 {
      text-align: left;
    }

    @media (max-width: 767px) {
      margin-bottom: em(20) !important;
    }
  }

  &__title {}

  &__topics {
    position: relative;
    margin-left: em(30);

    &__trigger {
      background: #fff;
      box-shadow: none;
      border: 1px solid #d1d1d1;
      padding: em(15);
      outline: none;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
      font-size: 13px;
      cursor: pointer;

      span {
        display: block;
        padding-right: em(20);

        &:after {
          content: '';
          display: inline;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid $blue;
          margin-left: 5px;
          vertical-align: middle;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &__menu {
      background-color: #fff;
      width: 100%;
      position: absolute;
      border-left: 1px solid #d1d1d1;
      border-right: 1px solid #d1d1d1;
      border-bottom: 1px solid #d1d1d1;
      z-index: 5;

      a {
        display: block;
        padding: em(5) 0;
        font-size: 16px !important;
      }

      > .inner {
        padding: 10px;
        padding-top: 0;
      }
    }

    &:not(.active) {
      .blog-header__topics__menu {
        display: none;
      }
    }

    &.active {
      .blog-header__topics__trigger {
        border-bottom: 1px solid #fff;
      }
    }
  }

  &__subscribe {
    .form-title:empty {
      display: none;
    }

    form {
      display: flex;
      align-items: stretch;
    }

    .hs_email {
      &, div.input {
        display: flex;
      }

      margin: 0 !important;

      label {
        display: none;
        visibility: hidden;
      }

      input {
        padding: em(10) em(15) !important;
        margin-right: 10px;
        max-width: 100%;
        font-size: 16px !important;
        min-width: em(200);

        @media (min-width: 768px) {
          width: em(340);
        }

        &.invalid,
        &.error {
          box-shadow: 0 2px 0 $gray-9 !important;
        }
      }

      @media (max-width: 767px) {
        flex-grow: 1 !important;
      }
    }

    input[type='submit'] {
      cursor: pointer;
      font-size: 13px;
    }
  }
}
