.post {
  @extend %card;

  position: relative;

  > .inner {
    display: block;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:hover {
      & + .inner {
        .post__title,
        .post__read-more {
          color: $orange;
        }
      }
    }
  }

  &__title {
    @extend %h4;

    display: block;
    transition: .3s;
  }

  &__date {
    display: none;
    margin-top: em(10);
  }

  &__content {
    display: block;
  }

  &__read-more {
    display: block;
    margin-top: em(10);
    color: $blue;
    font-weight: 700;
    text-decoration: underline;
    transition: .3s;
    font-size: 14px;
  }

  &__image {
    display: block;
    max-height: em(150);
    overflow: hidden;
    position: relative;
    padding-bottom: 56%;

    > .inner {
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    img {
      display: none !important;
    }
  }

  &:not(.card) {
    .post__image {
      max-height: none;
    }
  }
}
